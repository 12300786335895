import React, { useEffect, useState } from 'react';
import { Dropdown, ButtonToolbar, Notification} from 'rsuite';
import { useHistory } from 'react-router-dom';
import * as QueryString from 'query-string';
import * as Api from '../../api/end.points';
import SimpleTable from '../../components/table/SimpleTable';
import moment from 'moment';
import RsuitePaginationLumen from '../../components/Paginations/RsuitePaginationLumen';
import { Amount } from '../../services/Utils';
import DocumentsFilters from './DocumentsFilters';
import ModalVerDocumento from './ModalVerDocumento';
import ModalAgregarOrsan from './ModalAgregarOrsan';
import ModalBloquear from './ModalBloquear';
import ModalFechaProtesto from './ModalFechaProtesto';
import ModalDesbloquear from './ModalDesbloquear';
import ModalEnviar from './ModalEnviar';
import ModalRegistrarCanje from './ModalRegistrarCanje';
import ModalFechaRecepcion from './ModalFechaRecepcion';
import ModalTipoProtesto from './ModalTipoProtesto';
import ModalRespuestaOrsan from './ModalRespuestaOrsan';
import ModalAdjuntarImagen from './ModalAdjuntarImagen';

const GestionDocumentos = (props: any) => {
    const initDocument = {
        id: '',
        id_documento: '',
        cod_formapago: '',
        nro_ctacte: '',
        rut_tercero: '',
        dv_tercero: '',
        num_documento: '',
        fecha_doc: '',
        monto: '',
        estado: '',
        nombre: '',
        fecha_prog_ins: '',
        fecha_prog_mod: '',
        cod_orsan: '',
        fecha_cod_orsan: '',
        bloqueado: '',
        fecha_bloqueo: '',
        fecha_desbloqueo: '',
        fecha_envio: '',
    }
    type Document = typeof initDocument;
    const history = useHistory();
	const [list, setlist] = useState({data:null});
	const [listLoading, setlistLoading] = useState(true);
    const [document, setdocument] = useState<Document>(initDocument);
    const [banks, setBanks] = useState([]);
    const [modalShowVer, setModalShowVer] = useState(false);
    const [modalShowAgregar, setModalShowAgregar] = useState(false);
    const [modalShowBloquear, setModalShowBloquear] = useState(false);
    const [modalShowFechaProtesto, setModalShowFechaProtesto] = useState(false);
    const [modalShowTipoProtesto, setModalShowTipoProtesto] = useState(false);
    const [modalShowRespuestaOrsan, setModalShowRespuestaOrsan] = useState(false);
    const [modalShowFechaRecepcion, setModalShowFechaRecepcion] = useState(false);
    const [modalShowDesbloquear, setModalShowDesbloquear] = useState(false);
    const [modalShowEnviar, setModalShowEnviar] = useState(false);
    const [modalShowRegistrar, setModalShowRegistrar] = useState(false);
    const [modalAdjuntarImagen, setModalAdjuntarImagen] = useState(false);

    const runList = async () => {
        setlistLoading(true);
        setlist({
            ...list,
            data:null
        })
        let query:any = QueryString.parse(history.location.search);
        const getData:any = await Api.Documents(query);
        setlist({...getData})
        setlistLoading(false);
    }

    useEffect(()=>{
		runList();
	},[history]);

    const handleSelect = async (eventKey:any) =>{
        setlistLoading(true);
        if(eventKey.action !== 'liberate' && eventKey.action !== 'viewimage'){
            const getData:any = await Api.GetDocument({id: eventKey.id});
            setdocument(getData.document);
        }

        switch (eventKey.action) {
        case 'ver':
            handleShowVer();
            break;
        case 'addorsan':
            handleShowAgregar();
            break;
        case 'editorsan':
            handleShowAgregar();
            break;
        case 'block':
            handleShowBloquear();
            break;
        case 'dateprotest':
            handleShowFechaProtesto();
            break;
        case 'typeprotest':
            handleShowTipoProtesto();
            break;    
        case 'typeresponseorsan':
            handleShowRespuestaOrsan();
            break;                        
        case 'datereception':
            handleShowFechaRecepcion();
            break;            
        case 'desblock':
            handleShowDesbloquear();
            break;
        case 'send':
            handleShowEnviar();
            break;
        case 'registercanje':
            const getData:any = await Api.getBanks({});
            setBanks(getData.banks);
            handleShowRegistrar();
            break;
        case 'liberate':
            if(window.confirm('Confirma que desea liberar documento?')){
                const getData:any = await Api.LiberateDocument({id: eventKey.id});
                Notification['success']({
                    title: "Documento liberado con éxito",
                    placement: 'bottomEnd'
                });
                runList();
            }
            break;            
        case 'attach':
            handleShowAdjuntarImagen();
            break;            
        case 'viewimage':
            //window.open(getData.document.imagen, '_blank').focus();
            window.open(eventKey.imagen);
            break;            
        }
        
        setlistLoading(false);
    }

    const verDocumento = async id  => {
        const getData:any = await Api.GetDocument({id});
        setdocument(getData.document);
    }

    const fecha = (fecha:any) => {
        fecha = moment(fecha).local().format('DD/MM/YYYY');
        return fecha != 'Invalid date' ? fecha : ''; 
    }

    const handleCloseVer = () => setModalShowVer(false);
    const handleShowVer = () => setModalShowVer(true);

    const handleCloseAgregar = () => setModalShowAgregar(false);
    const handleShowAgregar = () => setModalShowAgregar(true);

    const handleCloseBloquear = () => setModalShowBloquear(false);
    const handleShowBloquear = () => setModalShowBloquear(true);

    const handleCloseFechaProtesto = () => setModalShowFechaProtesto(false);
    const handleShowFechaProtesto = () => setModalShowFechaProtesto(true);

    const handleCloseTipoProtesto = () => setModalShowTipoProtesto(false);
    const handleShowTipoProtesto = () => setModalShowTipoProtesto(true);

    const handleCloseRespuestaOrsan = () => setModalShowRespuestaOrsan(false);
    const handleShowRespuestaOrsan = () => setModalShowRespuestaOrsan(true);    

    const handleCloseFechaRecepcion = () => setModalShowFechaRecepcion(false);
    const handleShowFechaRecepcion = () => setModalShowFechaRecepcion(true);

    const handleCloseDesbloquear = () => setModalShowDesbloquear(false);
    const handleShowDesbloquear = () => setModalShowDesbloquear(true);

    const handleCloseEnviar = () => setModalShowEnviar(false);
    const handleShowEnviar = () => setModalShowEnviar(true);

    const handleCloseRegistrar = () => setModalShowRegistrar(false);
    const handleShowRegistrar = () => setModalShowRegistrar(true);

    const handleCloseAdjuntarImagen = () => setModalAdjuntarImagen(false);
    const handleShowAdjuntarImagen = () => setModalAdjuntarImagen(true);

    return (
        <React.Fragment>
            <ModalVerDocumento document={document} show={modalShowVer} handleClose={handleCloseVer} fecha={fecha} verDocumento={verDocumento} setdocument={setdocument} runList={runList} />
            <ModalAgregarOrsan document={document} show={modalShowAgregar} handleClose={handleCloseAgregar} fecha={fecha} runList={runList} />
            {/* <ModalBloquear document={document} show={modalShowBloquear} handleClose={handleCloseBloquear} fecha={fecha} runList={runList} /> */}
            {/* <ModalDesbloquear document={document} show={modalShowDesbloquear} handleClose={handleCloseDesbloquear} fecha={fecha} runList={runList} /> */}
            <ModalFechaProtesto document={document} show={modalShowFechaProtesto} handleClose={handleCloseFechaProtesto} fecha={fecha} runList={runList} />
            <ModalTipoProtesto document={document} show={modalShowTipoProtesto} handleClose={handleCloseTipoProtesto} fecha={fecha} runList={runList} />
            {/* <ModalRespuestaOrsan document={document} show={modalShowRespuestaOrsan} handleClose={handleCloseRespuestaOrsan} fecha={fecha} runList={runList} /> */}
            {/* <ModalEnviar document={document} show={modalShowEnviar} handleClose={handleCloseEnviar} fecha={fecha} runList={runList} /> */}
            <ModalRegistrarCanje document={document} show={modalShowRegistrar} handleClose={handleCloseRegistrar} fecha={fecha} runList={runList} banks={banks} />
            {/* <ModalFechaRecepcion document={document} show={modalShowFechaRecepcion} handleClose={handleCloseFechaRecepcion} fecha={fecha} runList={runList} /> */}
            <ModalAdjuntarImagen document={document} show={modalAdjuntarImagen} handleClose={handleCloseAdjuntarImagen} fecha={fecha} />

            <h5 className="py-2">
                Gestión de Documentos
                <hr className="my-2"/>
            </h5>
            <DocumentsFilters runList={runList}  />
            <SimpleTable
                messageEmpty={'No hay documentos'}
                data={list.data}
                onLoadingTable={listLoading}
                trHeader={[
                    {thClassName:'samll px-2 pb-0 text-white text-center',content:'#'},
                    {thClassName:'samll px-2 pb-0 text-white text-center',content:'FECHA DOC'},
                    {thClassName:'samll px-2 pb-0 text-white text-center',content:'NUM DOCUMENTO'},
                    {thClassName:'samll px-2 pb-0 text-white text-center',content:'MONTO'},
                    {thClassName:'samll px-2 pb-0 text-white text-center',content:'FORMA PAGO'},
                    {thClassName:'samll px-2 pb-0 text-white text-center',content:'ESTADO'},
                    {thClassName:'samll px-2 pb-0 text-white text-center',content:'ACCIONES'},
                ]}
                trBody={[
                    {tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'id'},
                    {
                        tdClassName:'small px-2 py-0 pt-1 text-center',
                        attrData:'fecha_doc',
                        content: item => moment(item).local().format('DD/MM/YYYY')
                    },
                    {tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'num_documento'},
                    {tdClassName:'small px-2 py-0 pt-1 text-right', attrData:'monto', content:item=>Amount(item)},
                    {tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'cod_formapago', content:item=> {
                        if(item == '106'){
                            return 'Cheque al Día';
                        } else if(item == '107'){
                            return 'Cheque a Fecha';
                        }
                    }},
                    {tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'estado', content:item=> {
                        if(item === 'DEP'){
                            return 'Depositado';
                        } else if(item === 'PRO'){
                            return 'Protestado';
                        } else if(item === 'VIG'){
                            return 'Vigente';
                        } else if(item === 'CAN'){
                            return 'Canjeado';
                        } else if(item === 'RCAN'){
                            return 'Canje Registrado';
                        } else if(item === 'ANU'){
                            return 'Anulado';
                        } else if(item === 'RDEP'){
                            return 'Redepositado';
                        } else if(item === 'LIB'){
                            return 'Liberado';
                        }
                    }},
                    {tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'options', content:(item,row)=>{
                        return(
                            <ButtonToolbar>
                                <Dropdown title="Acciones" onSelect={handleSelect} placement="bottomEnd">
                                    <Dropdown.Item eventKey={{action: 'ver', id: row?.id}}>Ver</Dropdown.Item>
                                    <Dropdown.Item divider />
                                    {!row.cod_orsan && <Dropdown.Item eventKey={{action: 'addorsan', id: row?.id}}>Agregar Código Consulta </Dropdown.Item>}
                                    {row.cod_orsan && row.estado !== 'LIB' && <Dropdown.Item eventKey={{action: 'editorsan', id: row?.id}}>Editar Código Consulta</Dropdown.Item>}
                                    {row.estado == 'PRO' && !(row.fecha_protesto) && <Dropdown.Item eventKey={{action: 'dateprotest', id: row?.id}}>Fecha Protesto</Dropdown.Item>}
                                    {row.estado == 'PRO' && row.fecha_protesto && <Dropdown.Item eventKey={{action: 'dateprotest', id: row?.id}}>Editar Fecha Protesto</Dropdown.Item>}
                                    {row.estado == 'PRO' && !(row.tipo_protesto) && <Dropdown.Item eventKey={{action: 'typeprotest', id: row?.id}}>Tipo Protesto</Dropdown.Item>}
                                    {row.estado == 'PRO' && row.tipo_protesto && <Dropdown.Item eventKey={{action: 'typeprotest', id: row?.id}}>Editar Tipo Protesto</Dropdown.Item>}

                                    {/* {row.cod_orsan && row.estado == 'PRO' && row.bloqueado != 1 && <Dropdown.Item eventKey={{action: 'block', id: row?.id}}>Bloquear</Dropdown.Item>}
                                    {row.cod_orsan && row.bloqueado == 1 && <Dropdown.Item eventKey={{action: 'block', id: row?.id}}>Editar Fecha Bloqueo</Dropdown.Item>}
                                    {row.cod_orsan && (row.estado == 'PRO' || row.estado == 'RDEP') && row.bloqueado == 1 && <Dropdown.Item eventKey={{action: 'desblock', id: row?.id}}>Desbloquear</Dropdown.Item>}
                                    {row.cod_orsan && row.bloqueado == 2 && <Dropdown.Item eventKey={{action: 'desblock', id: row?.id}}>Editar Fecha Desbloqueo</Dropdown.Item>}
                                    {row.cod_orsan && row.estado == 'PRO' && !(row.fecha_envio) && <Dropdown.Item eventKey={{action: 'send', id: row?.id}}>Enviar</Dropdown.Item>}
                                    {row.cod_orsan && row.estado !== 'LIB' && row.fecha_envio && <Dropdown.Item eventKey={{action: 'send', id: row?.id}}>Editar Fecha Envío</Dropdown.Item>}
                                    {row.cod_orsan && row.estado == 'PRO' && !(row.fecha_recepcion) && <Dropdown.Item eventKey={{action: 'datereception', id: row?.id}}>Fecha Recepción</Dropdown.Item>}
                                    {row.cod_orsan && row.estado == 'PRO' && row.fecha_recepcion && <Dropdown.Item eventKey={{action: 'datereception', id: row?.id}}>Editar Fecha Recepción</Dropdown.Item>}                                    
                                    {row.cod_orsan && row.estado == 'PRO' && !(row.respuesta_orsan) && <Dropdown.Item eventKey={{action: 'typeresponseorsan', id: row?.id}}>Respuesta Orsan</Dropdown.Item>}
                                    {row.cod_orsan && row.estado == 'PRO' && row.respuesta_orsan && <Dropdown.Item eventKey={{action: 'typeresponseorsan', id: row?.id}}>Editar Respuesta Orsan</Dropdown.Item>} */}

                                    {row.estado == 'CAN' && <Dropdown.Item eventKey={{action: 'registercanje', id: row?.id}}>Registrar Canje</Dropdown.Item>}
                                    {row.estado == 'VIG' && row.origen_id && <Dropdown.Item eventKey={{action: 'liberate', id: row?.id}}>Liberar</Dropdown.Item>}
                                    {row.estado == 'PRO' && <Dropdown.Item eventKey={{action: 'attach', id: row?.id}}>Adjuntar Imagen</Dropdown.Item>}
                                    {row.estado == 'PRO' && row.imagen && <Dropdown.Item eventKey={{action: 'viewimage', id: row?.id, imagen: row.picture}}>Ver Imagen</Dropdown.Item>}
                                </Dropdown>
                            </ButtonToolbar>
                        );
                    }},
                ]}
            />
            <RsuitePaginationLumen runList={runList} {...list}/>
        </React.Fragment>
    );
}

export default GestionDocumentos;