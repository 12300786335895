import hookup from './hookup.axios';

import Processing from './lumen.form';

export const LumenForm = new Processing();
// Auth

export const SingInIntranet = async ( data ) => await hookup('post','/sign/in/intranet',data);
export const SingIn = async ( data ) => await hookup('post','/auth/sign/in/admin',data);
export const SingUp = async ( data ) => await hookup('post','/auth/sign/up',data);
export const SignInConfig = async (data) => await hookup('post', '/auth/config', data);

//Recovery & change pass
export const RecoveryPass = async ( data ) => await hookup('post','/recovery/pass',data);
export const ChangePass = async ( data ) => await hookup('post','/chnage/pass',data);

// Invoices
export const InvoicesListing = async ( data ) => await hookup('get','/amsm/invoices/list',data);
export const LogListing = async ( data={} ) => await hookup('get','/amsm/log/list',data);


export const Shim0 = async ( data ) => await hookup('get','/bhes/shim0',data);
export const Shim1 = async ( data ) => await hookup('get','/bhes/shim1',data);
export const Shim2 = async ( data ) => await hookup('get','/bhes/shim2',data);
export const GeneratePayroll = async ( data ) => await hookup('get','/bhes/generar/nominas',data);

export const ShimAll = async ( data ) => await hookup('get','/bhes/allnocalzados',data);
export const getAllNC = async ( data ) => await hookup('get','/bhes/notas-credito-all',data);
export const getAllBHE = async ( data ) => await hookup('get','/bhes/anulaciones-bhe-all',data);

export const Bhes = async ( rut, data={} ) => await hookup('get',`/bhes/bhes/${rut}`,data);
export const PurchaseInvoices = async ( rut, data={} ) => await hookup('get',`/bhes/purchase/invoices/${rut}`,data);
export const SendMail = async (  data={} ) => await hookup('post','/bhes/send/mail',data);
export const SetCron = async ( data={} ) => await hookup('post','/bhes/set/crontab',data);
export const FindBhes = async ( data={} ) => await hookup('post','/bhes/find/bhes',data);

export const getAmountNomina = async ( id, data={} ) => await hookup('get',`/bhes/config/${id}`,data);
export const UpdateAmountNomina = async ( data ) => await hookup('put',`/bhes/config`,data);


export const AddCart = async ( data ) => await hookup('post','/shop/shopping/cart',data);

export const SendMailDocumentosNoCalzados = async (  data={} ) => await hookup('post','/bhes/send/mail/nocalces',data);
export const getEMailProfesional = async (rut , data={} ) => hookup('get',`/bhes/mail/profesional/${rut}`,data);

export const getTipoBaja = async () => await hookup('get','/bhes/configx/tipobaja',{});
export const getMotivoBaja = async ( ) => await hookup('get','/bhes/configx/motivobaja',{});
export const UpdateMotivoBaja = async (  data ) => await hookup('post','/bhes/configx/updateMotivoBaja',data);

export const getNC = async ( data ) => await hookup('get','/bhes/notas-credito',data);
export const getBHE = async ( data ) => await hookup('get','/bhes/anulaciones-bhe',data);

export const Documents = async ( data={} ) => await hookup('post','/documentos',data);
export const GetDocument = async ( data={} ) => await hookup('post','/getdocument',data);
export const SaveOrsan = async ( data={} ) => await hookup('post','/saveorsan',data);
export const BloquearDocumento = async ( data={} ) => await hookup('post','/bloqueardocumento',data);
export const DesbloquearDocumento = async ( data={} ) => await hookup('post','/desbloqueardocumento',data);
export const EnviarDocumento = async ( data={} ) => await hookup('post','/enviardocumento',data);
export const RegistrarCanje = async ( data={} ) => await hookup('post','/registrarcanje',data);
export const getBanks = async ( data={} ) => await hookup('post','/getbanks',data);
export const ReversarCanje = async ( data={} ) => await hookup('post','/reversarcanje',data);
export const GenerateCsv = async ( data={} ) => await hookup('post','/generatecsv',data);
export const LiberateDocument = async ( data={} ) => await hookup('post','/liberatedocument',data);
export const FechaProtesto = async ( data={} ) => await hookup('post','/fechaprotesto',data);
export const FechaRecepcion = async ( data={} ) => await hookup('post','/fecharecepcion',data);
export const TipoProtesto = async ( data={} ) => await hookup('post','/tipoprotesto',data);
export const RespuestaOrsan = async ( data={} ) => await hookup('post','/respuestaorsan',data);
export const RemoveImage = async ( data={} ) => await hookup('post','/removerimagen',data);