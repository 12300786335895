import React, { useState, useEffect } from 'react'
import { Col, ControlLabel, Form, Grid, Input, Row, DatePicker, Notification, FormGroup, SelectPicker, Button } from 'rsuite'
import SimpleLoading from '../../components/loading/SimpleLoading';
import ModalComponent from './ModalComponent'
import * as Api from '../../api/end.points';
import uniqid from 'uniqid';
import { Amount, Calendar } from '../../services/Utils';
import moment from 'moment';
import Inputmask from "inputmask";
import InfoDocumento from './InfoDocumento';

const ModalRegistrarCanje = (props) => {
    const {document: doc, show, handleClose, fecha, runList, banks} = props;
    const [inputs, setInputs]:any = useState({});
    const [data, setData]:any = useState([]);
    const [loading, setLoading]:any = useState(false);
    const [tipoCanje, setTipoCanje]:any = useState(0);
    const [totalCanje, setTotalCanje]:any = useState(0);
    const [intervenciones, setIntervenciones]:any = useState([]);
    

    const handleInput = (name:any, value:any) =>{
        if(name==='rut_titular'){
            var rule = '9{1,2}.9{3}.9{3}-(9|k|K)';
            var selector = document.getElementById("rut_titular");
            var im = new Inputmask(rule);
            im.mask(selector);
        }
        setInputs({
            ...inputs,
            [name]: value,
            id: doc.id
        });
	}

    var Fn = {
        // Valida el rut con su cadena completa "XXXXXXXX-X"
        validaRut : function (rutCompleto) {
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                return false;
            var tmp 	= rutCompleto.split('-');
            var digv	= tmp[1]; 
            var rut 	= tmp[0];
            if ( digv == 'K' ) digv = 'k' ;
            return (Fn.dv(rut) == digv );
        },
        dv : function(T){
            var M=0,S=1;
            for(;T;T=Math.floor(T/10))
                S=(S+T%10*(9-M++%6))%11;
            return S?S-1:'k';
        }
    }

    useEffect(() => {
        let date = new Date();
        let listIntervenciones = [];
        if(doc.intervenciones !== undefined){
            doc.intervenciones.forEach((intervencion) => {
                listIntervenciones.push({
                    "label": intervencion.Admision_Rol_Clinica + ' - ' + intervencion.rut_prof + '-' + Fn.dv( intervencion.rut_prof )  + ' - ' + intervencion.cod_prestacion + ' - $' + intervencion.monto_prestacion,
                    "value": intervencion.id + '-' + intervencion.monto_prestacion
                });
            });
            setIntervenciones(listIntervenciones);
        }

        setInputs({
            ...inputs,
            id: doc.id,
            fecha: date.toISOString().split('T')[0]
        });
	},[doc, banks]);

    const handleTipoCanje = (value) => setTipoCanje(value);
    const handleFormaPago = (value) => setInputs({...inputs, formaPago: value});
    const handleBanco = (value) => setInputs({...inputs, banco: value});
    const handleIntervencion = (value) => setInputs({...inputs, intervencion: value});   

    const options = [{
        "label": "Cheque",
        "value": "1"
      },
      {
        "label": "Transferencia",
        "value": "2"
      },
      {
        "label": "Tarjeta de Crédito",
        "value": "3"
      },
      {
        "label": "Descuento",
        "value": "4"
      }
    ];

    const quitarCanje = id => {
        let monto = data.find(x => x.id === id).monto;
        setTotalCanje((totalCanje => parseFloat(totalCanje) - parseFloat(monto)));

        setData(data.filter(item => item.id != id));
    }

    const validateField = field => {
        if(!inputs.hasOwnProperty(field) || inputs[field] == '' || inputs[field] == 'undefined')
            return false
        else 
            return true
    }

    const agregarCanje = () => {

        let datacanje;
        if(tipoCanje == 1){

            if(!validateField('rut_titular')){
                Notification['error']({
                    title: 'El rut es requerido',
                    placement: 'topStart',
                });            
                return;            
            }
    
            let rutFull = inputs.rut_titular.replace('_', '').replace('-', '').replaceAll('.', '');
            let rut = rutFull.substring(0, rutFull.length - 1);
            let dv = rutFull.substr(rutFull.length - 1);
    
            if(!Fn.validaRut(rut + '-' + dv)) {
                Notification['error']({
                    title: 'El rut ingresado es incorrecto',
                    placement: 'topStart',
                });            
                return;
            }
    
            if(!validateField('titular')){
                Notification['error']({
                    title: 'El Nombre del titular es requerido',
                    placement: 'topStart',
                });            
                return;            
            }

            if(!validateField('telefono')){
                Notification['error']({
                    title: 'El teléfono es requerido',
                    placement: 'topStart',
                });            
                return;            
            }

            if(!validateField('nro_documento')){
                Notification['error']({
                    title: 'El número del documento es requerido',
                    placement: 'topStart',
                });            
                return;            
            }
    
            if(!validateField('banco')){
                Notification['error']({
                    title: 'El Banco es requerido',
                    placement: 'topStart',
                });            
                return;            
            }

            if(!validateField('nro_ctacte')){
                Notification['error']({
                    title: 'La cuenta corriente es requerida',
                    placement: 'topStart',
                });            
                return;            
            }
    
            if(!validateField('formaPago')){
                Notification['error']({
                    title: 'La forma de pago es requerida',
                    placement: 'topStart',
                });            
                return;            
            }
            
            datacanje = {
                id: uniqid(),
                rut: rut,
                dv: dv,
                titular: inputs.titular,
                telefono: inputs.telefono,
                banco: inputs.banco,
                fecha: inputs.fecha,
                cod_orsan: inputs.cod_orsan,
                nro_documento: inputs.nro_documento,
                nro_ctacte: inputs.nro_ctacte,
                monto: inputs.monto,
                formaPago: inputs.formaPago,
                tipocanje: 'Cheque',
                document_id: doc.id
            }
        } else {

            let montoIntervencion = 0;
            let idIntervencion = 0;

            if(tipoCanje == 4){
                montoIntervencion = parseFloat(inputs.intervencion.split('-')[1]);
                idIntervencion = parseFloat(inputs.intervencion.split('-')[0]);
                if(inputs.monto > montoIntervencion){
                    Notification['error']({
                        title: 'Descuento debe ser menor al monto',
                        placement: 'topStart',
                    });            
                    return;            
                }
            }

            datacanje = {
                id: uniqid(),
                fecha: inputs.fecha,
                monto: inputs.monto,
                tipocanje: tipoCanje == 2 ? 'Transferencia' : tipoCanje == 3 ? 'Tarjeta de Crédito' : 'Descuento',
                document_id: doc.id,
                idIntervencion: idIntervencion
            }            
        }

        if(!validateField('fecha')){
            Notification['error']({
                title: 'La fecha del documento es requerida',
                placement: 'topStart',
            });            
            return;            
        }

        if(!validateField('monto')){
            Notification['error']({
                title: 'El monto es requerido',
                placement: 'topStart',
            });            
            return;            
        }

        setTotalCanje((totalCanje => parseFloat(totalCanje) + parseFloat(inputs.monto)));

        setData([
            ...data,
            datacanje
        ])
        
        let date = new Date();

        setInputs({'fecha': date.toISOString().split('T')[0]});
        setTipoCanje(0);
    }

    const RegistrarCanje = async () => {

        const monto = parseFloat(doc.monto);
        if(monto != totalCanje){
            Notification['error']({
                title: 'El monto de canjes debe ser: ' + Amount(doc.monto),
                placement: 'topStart',
            });            
            return;
        }
        setLoading(true);
        const saveData:any = await Api.RegistrarCanje({data});

        Notification['success']({
            title: "Canjeado con éxito",
            placement: 'bottomEnd'
        });
        setTotalCanje(0);
        setData([]);
        runList();
        handleClose();
        setLoading(false);
    }

    return (
        <ModalComponent show={show} handleClose={handleClose} title="Registrar Canje" guardar={RegistrarCanje}>
            {loading ? <SimpleLoading /> :
            <Form>
                <Grid fluid>
                    <InfoDocumento document={doc} fecha={fecha} />
                    <Row>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Tipo de Canje</ControlLabel>
                            <SelectPicker
                                value={tipoCanje}
                                onChange={handleTipoCanje}
                                data={options}
                                style={{ width: 224 }}
                                size="xs"
                            />
                        </Col>
                    </Row>
                    <hr />
                    {tipoCanje == 1 && <><Row>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Rut Titular</ControlLabel>
                            <Input size="xs" type="rut" id="rut_titular" value={inputs.rut_titular}
                                onChange={ (e:any, ev:any) => {
                                    handleInput('rut_titular', ev.target.value)
                                }}  
                            />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Nombre titular</ControlLabel>
                            <Input size="xs" value={inputs.titular}
                                onChange={ (e:any, ev:any) => {
                                    handleInput('titular', ev.target.value)
                                }}  
                            />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Teléfono</ControlLabel>
                            <Input size="xs" value={inputs.telefono}
                                onChange={ (e:any, ev:any) => {
                                    handleInput('telefono', ev.target.value)
                                }}  
                            />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Numero Documento</ControlLabel>
                            <Input size="xs" value={inputs.nro_documento}
                                onChange={ (e:any, ev:any) => {
                                    handleInput('nro_documento', ev.target.value)
                                }}  
                            />
                        </Col>
                    </Row>                        
                    <Row>
                    <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Banco</ControlLabel>
                            <SelectPicker
                                value={inputs.banco}
                                onChange={handleBanco}
                                data={banks}
                                style={{ width: 224 }}
                                size="xs"
                            />

                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Nro Cta corriente</ControlLabel>
                            <Input size="xs" value={inputs.nro_ctacte}
                                onChange={ (e:any, ev:any) => {
                                    handleInput('nro_ctacte', ev.target.value)
                                }}  
                            />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Fecha Documento</ControlLabel>
                            <DatePicker block size="xs" value={inputs.fecha} 
                                locale={Calendar}
                                onChange={ (e:any) => {
                                    if (e) {
                                        let year = e.getFullYear();
                                        let month = e.getMonth()+1;
                                        let day = e.getDate();
                                        day =  day >= 10 ? day : `0${day}`;
                                        month =  month >= 10 ? month : `0${month}`;
                                        let fecha = `${year}-${month}-${day}`;
                                        handleInput('fecha', fecha)
                                    } else {
                                        handleInput('fecha', '')
                                    }
                                }}
                            />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Código Consulta</ControlLabel>
                            <Input size="xs" value={inputs.cod_orsan}
                                onChange={ (e:any, ev:any) => {
                                    handleInput('cod_orsan', ev.target.value)
                                }}  
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Monto</ControlLabel>
                            <Input size="xs" value={inputs.monto}
                                onChange={ (e:any, ev:any) => {
                                    handleInput('monto', ev.target.value)
                                }}  
                            />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Forma de Pago</ControlLabel>
                            <SelectPicker
                                value={inputs.formaPago}
                                onChange={handleFormaPago}
                                data={[{
                                    "label": "Cheque al día",
                                    "value": "106",
                                    "role": "Master",
                                  },
                                  {
                                    "label": "Cheque a Fecha",
                                    "value": "107",
                                    "role": "Master",
                                  }]}
                                style={{ width: 224 }}
                                size="xs"
                            />
                        </Col>
                        <Col md={6}>
                            {tipoCanje != 0 && <Button size="xs" onClick={agregarCanje} appearance="subtle" active style={{marginTop: '26px' , color: 'white' , backgroundColor: '#35485f'}}>Agregar</Button>}
                        </Col>
                    </Row>
                    </>}
                    {(tipoCanje == 2 || tipoCanje == 3 || tipoCanje == 4) && <><Row>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>{tipoCanje == 4 ? 'Fecha Descuento' : 'Fecha Pago' }</ControlLabel>
                            <DatePicker block size="xs" value={inputs.fecha} 
                                locale={Calendar}
                                onChange={ (e:any) => {
                                    if (e) {
                                        let year = e.getFullYear();
                                        let month = e.getMonth()+1;
                                        let day = e.getDate();
                                        day =  day >= 10 ? day : `0${day}`;
                                        month =  month >= 10 ? month : `0${month}`;
                                        let fecha = `${year}-${month}-${day}`;
                                        handleInput('fecha', fecha)
                                    } else {
                                        handleInput('fecha', '')
                                    }
                                }}
                            />
                        </Col>
                        {tipoCanje == 4 && <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Intevención</ControlLabel>
                            <SelectPicker
                                value={inputs.intervencion}
                                onChange={handleIntervencion}
                                data={intervenciones}
                                style={{ width: 224 }}
                                size="xs"
                            />
                        </Col>}
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Monto</ControlLabel>
                            <Input size="xs" value={inputs.monto}
                                onChange={ (e:any, ev:any) => {
                                    handleInput('monto', ev.target.value)
                                }}  
                            />
                        </Col>
                        <Col md={6}>
                        {tipoCanje != 0 && <Button size="xs" onClick={agregarCanje} appearance="subtle" active style={{marginTop: '26px' , color: 'white' , backgroundColor: '#35485f'}}>Agregar</Button>}
                        </Col>

                    </Row></>}
                    <hr />
                    <Row>
                        <Col md={24}>
                            <table className='table table-hover shadow simple-table table-bordered'>
                                <thead>
                                    <tr>
                                        <th className='samll px-2 pb-0 text-white text-center'> Tipo </th>
                                        <th className='samll px-2 pb-0 text-white text-center'> Monto </th>
                                        <th className='samll px-2 pb-0 text-white text-center'> Fecha </th>
                                        <th className='samll px-2 pb-0 text-white text-center'> Acción </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(( row, index ) => {
                                        return (
                                            <tr key={index}>
                                            <td style={{fontSize: '12px'}}> {row.tipocanje} </td>
                                            <td style={{fontSize: '12px'}}> {Amount(row.monto)} </td>
                                            <td style={{fontSize: '12px'}}> {moment(row.fecha).local().format('DD/MM/YYYY')} </td>
                                            <td style={{width: '25%'}} className='text-center'> 
                                                <Button size="xs" appearance="subtle" onClick={() => quitarCanje(row.id)}>
                                                    Eliminar
                                                </Button>
                                            </td>
                                        </tr>
                                        );
                                    })}
                                   </tbody>
                            </table>
                        </Col>
                    </Row>
                    
                </Grid>
            </Form>}
        </ModalComponent>
    )
}

export default ModalRegistrarCanje