import React from 'react';

import { formatNumber } from '../../helpers/Strings';
import { Notification } from 'rsuite';

const InputCheckBox = (props:any) => {

    const handleChkBox = (item , e:any) => {


        let data_resumen_montobruto = Number.parseFloat(document.getElementById('data-resumen_montobruto').dataset.resumen_montobruto);
        let data_resumen_montoretencion = Number.parseFloat(document.getElementById('data-resumen_montoretencion').dataset.resumen_montoretencion);
        let data_resumen_montoneto = Number.parseFloat(document.getElementById('data-resumen_montoneto').dataset.resumen_montoneto);
        
        let hdd_label_records:any =  document.getElementById('hdd_label_records');
        let records = hdd_label_records.value;

        let hdd_dte_selected:any = document.getElementById('hdd_calce_id_selected');
        let dte_selected = hdd_dte_selected.value;

        let hdd_rut_selected:any = document.getElementById('hdd_rut_selected');
        
        let id_concat = props.dte.rut_prestador + props.dte.rut_receptor + '*' + props.dte.razon_social + '*';


        if(dte_selected <= 0 ) {
            
            hdd_dte_selected.value = id_concat;
        
        } else {

            if( hdd_dte_selected.value != id_concat) {
                Notification['error']({
                    title: 'Solo se pueden seleccionar del mismo DTE.',
                    placement: 'topStart',
                });
                e.preventDefault();
                return false;
            }

        }

		if(e.target.checked) {
                    
            data_resumen_montobruto  = data_resumen_montobruto + Number.parseFloat(item.Monto_Bruto);
            data_resumen_montoretencion = data_resumen_montoretencion + Number.parseFloat(item.Retencion_Efectuada);
            data_resumen_montoneto = data_resumen_montobruto - data_resumen_montoretencion;
            records = Number.parseInt(records) + 1;

        } else {
                    
            data_resumen_montobruto  = data_resumen_montobruto - Number.parseFloat(item.Monto_Bruto);
            data_resumen_montoretencion = data_resumen_montoretencion - Number.parseFloat(item.Retencion_Efectuada);
            data_resumen_montoneto = data_resumen_montobruto + data_resumen_montoretencion;
            records = Number.parseInt(records) - 1;
        }

                document.getElementById('data-resumen_montobruto').textContent = "$ " + ( data_resumen_montobruto > 0 ? formatNumber(data_resumen_montobruto) : '0');
                document.getElementById('data-resumen_montobruto').dataset.resumen_montobruto = String(data_resumen_montobruto);

                document.getElementById('data-resumen_montoretencion').textContent = "$ " + ( data_resumen_montoretencion > 0 ? formatNumber(data_resumen_montoretencion) : '0');
                document.getElementById('data-resumen_montoretencion').dataset.resumen_montoretencion = String(data_resumen_montoretencion);

                document.getElementById('data-resumen_montoneto').textContent = "$ " + ( data_resumen_montoneto > 0 ? formatNumber(data_resumen_montoneto) : '0');
                document.getElementById('data-resumen_montoneto').dataset.resumen_montoneto = String(data_resumen_montoneto);

                hdd_label_records.value  = records;

                if(records <= 0) {

                    hdd_dte_selected.value = 0;
                
                }

                hdd_rut_selected.value = item.Rut_Profesional
	}

    return(<>
             <input type="checkbox" onClick={(e) => handleChkBox(props.item,e)} style={{ marginTop: '20px' }} />
    </>)
}

export default InputCheckBox;