import React from 'react'
import { Col, ControlLabel, Form, Grid, Input, Row, DatePicker, Notification } from 'rsuite'

const InfoDocumento = props => {
    const {document, fecha} = props;
    return (
        <>
            <Row>
                <Col md={6}>
                    <ControlLabel style={{ fontSize: '12px' }}>Rut tercero</ControlLabel>
                    <Input size="xs" readOnly={true} value={document.rut_tercero !== '' ? document.rut_tercero + '-' + document.dv_tercero : ''} />
                </Col>
                <Col md={6}>
                    <ControlLabel style={{ fontSize: '12px' }}>NRO Documento</ControlLabel>
                    <Input size="xs" readOnly={true} value={document.num_documento} />
                </Col>
                <Col md={6}>
                    <ControlLabel style={{ fontSize: '12px' }}>Fecha Documento</ControlLabel>
                    <Input size="xs" readOnly={true} value={fecha(document.fecha_doc)} />
                </Col>
                <Col md={6}>
                    <ControlLabel style={{ fontSize: '12px' }}>Monto</ControlLabel>
                    <Input size="xs" readOnly={true} value={document.monto} />
                </Col>
            </Row>
            <hr />
        </>
    )
}

export default InfoDocumento