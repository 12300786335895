import React from 'react';
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { InfoSession, CloseSession } from '../api/session';

import Imgs from '../components/media/Imgs';
import NavbCollapse from '../components/nav/NavCollapse';
import {MenuOne} from './MenuAdmin';

import  Actions from '../redux/actions';
import { Container, Content, Dropdown, Icon, Nav, Sidebar, Sidenav } from 'rsuite';

// import * as Api from '../api/end.points'
const LayoutAdmin = ( props:any ) => {
	const dispatch = useDispatch();
	const panelStyles = {
        padding: '15px 20px',
        color: '#aaa'
    };

    const headerStyles = {
        padding: 20,
        fontSize: 16,
        background: '#0166a2',
        color: ' #fff',
		height: '80px'
    };

	const navBodyStyle = {
        overflow: 'auto'
	};
	
	return(
		<React.Fragment>
			<nav className="nav-top-1 nav nav-pills nav-justified py-3 px-5bg-white">
				<div className="container-fluid px-5">
					<div className="row w-100">
						<div className="col-md-6">
							<Link to="/">
								<img src={Imgs.LogoAmsmGris} alt="LogoAmsmGris" className="logo-gris"/>
							</Link>
						</div>
						<div className="col-md-6 align-self-star text-right">
							<div className="btn-group">
								<a href="https://intranet.amsm.cl/" className="btn btn-light">Intranet </a>
								<span className="btn btn-light">Usuario: { InfoSession().fullName } </span>
								<button
									type="button"
									className="btn btn-light"
									onClick={()=>{
										dispatch(Actions.Auth.logout())
									}}
								>
									Cerrar Sesión
								</button>
							</div>
						</div>
					</div>
				</div>
			</nav>
			<NavbCollapse items={MenuOne}/>
			<div className="container-app container-fluid px-5 pt-3 bg-white">
				{/*<button type="button" className="btn btn-primary" onClick={addCart}>test</button>*/}
			{props.children}
			</div>
		</React.Fragment>
	);
}
export default  LayoutAdmin;