import React, { useState, useEffect } from 'react'
import { Col, ControlLabel, Form, Grid, Input, Row, DatePicker, Notification } from 'rsuite'
import SimpleLoading from '../../components/loading/SimpleLoading';
import ModalComponent from './ModalComponent'
import * as Api from '../../api/end.points';
import InfoDocumento from './InfoDocumento';
import { Calendar } from '../../services/Utils';

const ModalFechaProtesto = (props) => {
    const {document, show, handleClose, fecha, runList} = props;
    const [inputs, setInputs]:any = useState({});
    const [loading, setLoading]:any = useState(false);

    const handleInput = (name:any, value:any) =>{
        setInputs({
            ...inputs,
            [name]: value,
            id: document.id
        });
	}

    useEffect(()=>{
        setInputs({
            ...inputs,
            fecha: document.fecha_protesto,
            id: document.id
        });
	},[document]);

    const GuardarFecha = async () => {
        
        if(!('fecha' in inputs) || inputs.fecha === '' || !inputs.fecha){
            Notification['error']({
                title: 'Debe seleccionar una Fecha de protesto.',
                placement: 'topStart',
            });
            return false;
        }

        setLoading(true);
        
        const saveData:any = await Api.FechaProtesto(inputs);
        Notification['success']({
            title: "Fecha agregada con éxito",
            placement: 'bottomEnd'
        });

        setLoading(false);

        runList();
        handleClose();
    }

    return (
        <ModalComponent show={show} handleClose={handleClose} title="Fecha Protesto Documento" guardar={GuardarFecha}>
            {loading ? <SimpleLoading /> :
            <Form>
                <Grid fluid>
                    <InfoDocumento document={document} fecha={fecha} />
                    <Row>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Fecha protesto</ControlLabel>
                            <DatePicker block value={inputs.fecha} 
                                locale={Calendar}
                                onChange={ (e:any) => {
                                    if (e) {
                                        let year = e.getFullYear();
                                        let month = e.getMonth()+1;
                                        let day = e.getDate();
                                        day =  day >= 10 ? day : `0${day}`;
                                        month =  month >= 10 ? month : `0${month}`;
                                        let fecha = `${year}-${month}-${day}`;
                                        handleInput('fecha', fecha)
                                    } else {
                                        handleInput('fecha', '')
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </Grid>
            </Form>}
        </ModalComponent>
    )
}

export default ModalFechaProtesto