import React, { useEffect } from 'react';
import * as QueryString from 'query-string';
import { useDispatch } from 'react-redux';
import { withRouter,useHistory } from 'react-router-dom';
import { OpenSession } from '../../api/session'
import  * as Api from '../../api/end.points';
import  Actions from '../../redux/actions';
const SingInIntranet = () => {
  const dispatch = useDispatch();
  const history = useHistory();
	const query:any = QueryString.parse(history.location.search);
  useEffect(()=>{
    const auth = async () => {
      const data:any = await Api.SingInIntranet(query);
      if (data.session) {
        OpenSession(data.session);
        window.location.href = '/'
      }else{
        window.location.href = '/sign/in'
      }
    }
    auth();
  },[]);
  return(
    <div>
      <h1>Procesando..</h1>
    </div>
  );
}

export default SingInIntranet;