import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import * as QueryString from 'query-string';
import { withRouter,useHistory } from 'react-router-dom';
import { Button, FormGroup, ControlLabel, HelpBlock, Form, Notification } from 'rsuite';
import { DateRangePicker } from 'rsuite';
import ReactExport from "react-export-excel";
import  actions from '../../redux/actions'
import * as Api from '../../api/end.points';
import moment from 'moment';
import { Amount, Calendar } from '../../services/Utils';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DocumentsFilters = ( props:any ) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const query:any = QueryString.parse(history.location.search);
	const [inputs, setInputs]:any = useState({...query});
	const { InvoiceAction } = actions;
	const [ExcelLoading, setExcelLoading]:any = useState(false);
	const [listExcel, setlistExcel] = useState([]);

	if (typeof inputs.fechadocdesde==="undefined") {
		delete query.fechadocdesde;
		delete query.fechadochasta;
	}
	if (typeof inputs.fechamoddesde==="undefined") {
		delete query.fechamoddesde;
		delete query.fechamodhasta;
	}
	if (typeof inputs.rut==="undefined" || inputs.rut==="") {
		delete query.rut;
		delete inputs.rut;
	}

	const GenerateExcel = async () => {
		setExcelLoading(true);
		let query:any = QueryString.parse(history.location.search);
        const rows:any = await Api.GenerateCsv(query);
		if(rows.length > 0){
			setlistExcel(rows);
			var link = document.getElementById('generateExcel');
			link.click();	
		} else {
			Notification['error']({
                title: 'No se encontraron registros.',
                placement: 'topStart',
            });
		}
		setExcelLoading(false);
	}

	const SearchInformation = (excel = false) => {

		if (typeof inputs.generados==="undefined" || inputs.generados===false) {
			delete query.generados;
			delete inputs.generados;
		}
		if (typeof inputs.fechadocdesde==="undefined") {
			delete query.fechadocdesde;
			delete query.fechadochasta;
			delete inputs.fechadocdesde;
			delete inputs.fechadochasta;
		}
		if (typeof inputs.fechamoddesde==="undefined") {
			delete query.fechamoddesde;
			delete query.fechamodhasta;
			delete inputs.fechamoddesde;
			delete inputs.fechamodhasta;
		}
		if (typeof inputs.fechatradesde==="undefined") {
			delete query.fechatradesde;
			delete query.fechatrahasta;
			delete inputs.fechatradesde;
			delete inputs.fechatrahasta;
		}
		if (typeof inputs.nrodocumento==="undefined" || inputs.nrodocumento==="") {
			delete query.nrodocumento;
			delete inputs.nrodocumento;
		} else {
			if(typeof inputs.fechadocdesde!=="undefined"){
				delete query.fechadocdesde;
				delete query.fechadochasta;
				delete inputs.fechadocdesde;
				delete inputs.fechadochasta;
			}
			if(typeof inputs.fechamoddesde!=="undefined"){
				delete query.fechamoddesde;
				delete query.fechamodhasta;
				delete inputs.fechamoddesde;
				delete inputs.fechamodhasta;
			}
			if(typeof inputs.fechatradesde!=="undefined"){
				delete query.fechatradesde;
				delete query.fechatrahasta;
				delete inputs.fechatradesde;
				delete inputs.fechatrahasta;
			}
		}
		if (typeof inputs.rol==="undefined" || inputs.rol==="") {
			delete query.rol;
			delete inputs.rol;
		}
		if (typeof inputs.estado==="undefined" || inputs.estado==="") {
			delete query.estado;
			delete inputs.estado;
		}

		if (typeof inputs.estadointerno==="undefined" || inputs.estadointerno==="") {
			delete query.estadointerno;
			delete inputs.estadointerno;
		}

		delete query.page;
		delete inputs.page;

		history.push({
			pathname: history.location.pathname,
			search:QueryString.stringify({
				...query,
				...inputs
			}),
			state: {
				...query,
				...inputs,
				page:null
			}
		})

		if(excel){
			GenerateExcel();
			return;
		}
		props.runList(excel);
	}

	const validafecha = (data:any, fechadesde:string, fechahasta:string) => {
		let yaer:any=0;
		let month:any=0;
		let day:any=0;
		let range={};
		for (var i = 0; i < data.length; ++i) {
			yaer = data[i].getFullYear();
			month = data[i].getMonth()+1;
			day = data[i].getDate();
			month = month >= 10 ? month : `0${month}`;
			day =  day >= 10 ? day : `0${day}`;
			range[i===0?fechadesde:fechahasta] = `${yaer}-${month}-${day}`;
		}		
		return range;
	}

	const dgv = T =>
	{  
		var M=0,S=1;
		for(;T;T=Math.floor(T/10))
		S=(S+T%10*(9-M++%6))%11;
		return S?S-1:'k';
	}

	
	const handleInput = (e:any) =>{
		const target = e.target.name;
		if (target==='fechadoc' || target==='fechamod' || target==='fechatra') {
			const data = e.target.value;
			let range = {};
			switch (target) {
				case 'fechadoc':
					range = validafecha(data, 'fechadocdesde', 'fechadochasta');
					if (data.length===0) {
						delete inputs.fechadocdesde;
						delete inputs.fechadochasta;
					}
					break;
				case 'fechamod':
					range = validafecha(data, 'fechamoddesde', 'fechamodhasta');
					if (data.length===0) {
						delete inputs.fechamoddesde;
						delete inputs.fechamodhasta;
					}
					break;
				case 'fechatra':
					range = validafecha(data, 'fechatradesde', 'fechatrahasta');
					if (data.length===0) {
						delete inputs.fechatradesde;
						delete inputs.fechatrahasta;
					}
					break;					
			}
			setInputs({
				...inputs,
				...range
			});
		} else {
			setInputs({
				...inputs,
				[target]: e.target.value
			});
		}
	}

	return(
		<Form layout="inline" className="pb-0">
			<FormGroup>
				<ControlLabel>Número Documento {<HelpBlock tooltip>Número de Documento</HelpBlock>}</ControlLabel>
				<br/>
				<div className="row">
					<div className="col-12">
						<input
							id="nrodocumento"
							name="nrodocumento"
							type='number'
							className="form-control"
							onChange={handleInput}
							value={inputs.nrodocumento}
							// style={{ width: '200px'}}
						/>
					</div>
				</div>
			</FormGroup>
			<FormGroup>
				<ControlLabel>Rol Clinica {<HelpBlock tooltip>Rol Clinica</HelpBlock>}</ControlLabel>
				<br/>
				<div className="row">
					<div className="col-12">
						<input
							id="rol"
							name="rol"
							type='number'
							className="form-control"
							onChange={handleInput}
							value={inputs.rol}
							// style={{ width: '200px'}}
						/>
					</div>
				</div>
			</FormGroup>
			<FormGroup>
		      <ControlLabel>Fecha Documento <HelpBlock tooltip>Es la fecha de documento</HelpBlock></ControlLabel>
		      <br/>
		      <DateRangePicker
			  showOneCalendar
			  locale={Calendar}
				value={typeof inputs.fechadocdesde==="undefined" ? [] : [
			      	inputs.fechadocdesde,
					inputs.fechadochasta
				]}
				  onChange={(e)=>{
					handleInput({
						target:{
							name:'fechadoc',
							value:e
						}
					})
				}}
			    />
		    </FormGroup>
			<FormGroup>
		      <ControlLabel>Fecha Modificación <HelpBlock tooltip>Es la fecha de modificación del documento</HelpBlock></ControlLabel>
		      <br/>
		      <DateRangePicker
			  	showOneCalendar
			  	locale={Calendar}
				value={typeof inputs.fechamoddesde==="undefined" ? [] : [
			      	inputs.fechamoddesde,
					inputs.fechamodhasta
				]}
				  onChange={(e)=>{
					handleInput({
						target:{
							name:'fechamod',
							value:e
						}
					})
				}}
			    />
		    </FormGroup>
			<FormGroup>
		      <ControlLabel>Fecha Transacción <HelpBlock tooltip>Es la fecha de transacción de las intervenciones del documento</HelpBlock></ControlLabel>
		      <br/>
		      <DateRangePicker
			  	showOneCalendar
			  	locale={Calendar}
				value={typeof inputs.fechatradesde==="undefined" ? [] : [
			      	inputs.fechatradesde,
					inputs.fechatrahasta
				]}
				  onChange={(e)=>{
					handleInput({
						target:{
							name:'fechatra',
							value:e
						}
					})
				}}
			    />
		    </FormGroup>
			<FormGroup>
				<div className="rs-form-group">
					<label htmlFor="">Estado</label>
					<select name="estado" className="form form-control" value={inputs.estado} onChange={handleInput} style={{ width: '200px'}}>
						<option value="">Todos</option>
						<option value="VIG">Vigente</option>
						<option value="DEP">Depositado</option>
						<option value="PRO">Protestado</option>
						<option value="ANU">Anulado</option>
						<option value="CAN">Canjeado</option>
						<option value="RDEP">Redepositado</option>
						<option value="RCAN">Canje Registrado</option>
						<option value="LIB">Liberado</option>
					</select>
				</div>
			</FormGroup>	
			{/* <FormGroup>
				<div className="rs-form-group">
					<label htmlFor="">Estado Interno</label>
					<select name="estadointerno" className="form form-control" value={inputs.estadointerno} onChange={handleInput} style={{ width: '200px'}}>
						<option value="">Todos</option>
						<option value="1">Sin Orsan</option>
						<option value="2">Con Orsan</option>
						<option value="3">Bloqueado</option>
						<option value="4">Desbloqueado</option>
						<option value="5">Enviado</option>
					</select>
				</div>
			</FormGroup>	 */}
			<FormGroup>
				<div className="rs-form-group">
					<label htmlFor="">Documentos Canje</label>
					<input type="checkbox" name="generados" className="form form-control" style={{fontSize: '8px'}} checked={inputs.generados}   onChange={(e) => {
						handleInput({
						target: {
							name: e.target.name,
							value: e.target.checked,
						},
						});
					}} />
				</div>
			</FormGroup>				
		    <FormGroup>

		    	<Button appearance="subtle" active onClick={() => SearchInformation()} style={{marginTop: '35px' , color: 'white' , backgroundColor: '#35485f'}}>Buscar</Button>
				{' '}
				<Button appearance="subtle" active onClick={() => SearchInformation(true)} style={{marginTop: '35px' , color: 'white' , backgroundColor: '#35485f'}} loading={ExcelLoading} disabled={ExcelLoading}>Excel</Button>
				{' '}
				<ExcelFile element={<Button id="generateExcel" style={{display: 'none'}}>Excel</Button>}>
					<ExcelSheet data={listExcel} name="Documentos">
						<ExcelColumn label="Rol" value={row => row.Admision_Rol_Clinica + '-' + dgv(row.Admision_Rol_Clinica) }/>
						<ExcelColumn label="Fecha Doc" value={row => moment(row.fecha_doc).local().format('DD/MM/YYYY') }/>
						<ExcelColumn label="Fecha Caja" value={row => moment(row.fecha_transaccion).local().format('DD/MM/YYYY') }/>
						<ExcelColumn label="Num Documento" value="num_documento"/>
						<ExcelColumn label="Monto" value="monto"/>
						<ExcelColumn label="Forma Pago" value={row => row.cod_formapago == '106' ? 'Cheque al Día AMSM' : 'Cheque a Fecha AMSM' }/>
						<ExcelColumn label="Nro CtaCte" value="nro_ctacte"/>
						<ExcelColumn label="Rut tercero" value="rut_tercero"/>
						<ExcelColumn label="DV tercero" value="dv_tercero"/>
						<ExcelColumn label="Cod Banco" value="Codigo_Banco"/>
						<ExcelColumn label="Banco" value="Banco"/>
						<ExcelColumn label="Estado" value={row => {
							if(row.estado === 'DEP'){
								return 'Depositado';
							} else if(row.estado === 'PRO'){
								return 'Protestado';
							} else if(row.estado === 'VIG'){
								return 'Vigente';
							} else if(row.estado === 'CAN'){
								return 'Canjeado';
							} else if(row.estado === 'RCAN'){
								return 'Canje Registrado';
							} else if(row.estado === 'ANU'){
								return 'Anulado';
							} else if(row.estado === 'RDEP'){
								return 'Redepositado';
							} else if(row.estado === 'LIB'){
								return 'Liberado';
							}
						}}/>
						<ExcelColumn label="Nombre Titular" value="nombre"/>
						<ExcelColumn label="Teléfono" value="telefono"/>
						<ExcelColumn label="Monto Prestación" value="monto_prestacion"/>
						<ExcelColumn label="Fecha Prog mod" value={row => row.fecha_prog_mod ? moment(row.fecha_prog_mod).local().format('DD/MM/YYYY') : ''}/>
						<ExcelColumn label="Cod orsan" value="cod_orsan"/>
						<ExcelColumn label="Tipo Protesto" value="ripo_protesto"/>
						<ExcelColumn label="Fecha cod orsan" value={row => row.fecha_cod_orsan ? moment(row.fecha_cod_orsan).local().format('DD/MM/YYYY') : ''}/>
						<ExcelColumn label="Fecha bloqueo" value={row => row.fecha_bloqueo ? moment(row.fecha_bloqueo).local().format('DD/MM/YYYY') : ''}/>
						<ExcelColumn label="Fecha desbloqueo" value={row => row.fecha_desbloqueo ? moment(row.fecha_desbloqueo).local().format('DD/MM/YYYY') : ''}/>
						<ExcelColumn label="Fecha envío" value={row => row.fecha_envio ? moment(row.fecha_envio).local().format('DD/MM/YYYY') : ''}/>
						<ExcelColumn label="Fecha protesto" value={row => row.fecha_protesto ? moment(row.fecha_protesto).local().format('DD/MM/YYYY') : ''}/>
						<ExcelColumn label="Fecha recepcion" value={row => row.fecha_recepcion ? moment(row.fecha_recepcion).local().format('DD/MM/YYYY') : ''}/>
						<ExcelColumn label="Fecha respuesta" value={row => row.fecha_respuesta ? moment(row.fecha_respuesta).local().format('DD/MM/YYYY') : ''}/>
						<ExcelColumn label="Respuesta Orsan" value="respuesta_orsan"/>
						<ExcelColumn label="Respuesta Orsan Observaciones" value="respuesta_orsan_observaciones"/>
					</ExcelSheet>
            	</ExcelFile>
		    </FormGroup>
		</Form>
	);
}
export default withRouter(DocumentsFilters);
// Fecha cod orsan	Fecha bloqueo	Fecha desbloqueo	Fecha envio	fecha Respuesta	respuesta 

//Nombre Banco pendiente