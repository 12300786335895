import moment from 'moment';
import React, { useState } from 'react'
import { Col, ControlLabel, Form, Grid, Input, Row, Table, Nav, Icon, Button, Notification } from 'rsuite'
import SimpleTable from '../../components/table/SimpleTable';
import ModalComponent from './ModalComponent'
import { Amount } from '../../services/Utils';
import SimpleLoading from '../../components/loading/SimpleLoading';
import * as Api from '../../api/end.points';

const ModalVerDocumento = (props) => {
    const {document, show, handleClose, fecha, setdocument, runList} = props;
    const [active, setActive] = useState('home');
    const onSelect = activeKey => setActive(activeKey);
    const [loading, setLoading]:any = useState(false);

    const handleVer = async id => {
        setLoading(true);
        const getData:any = await Api.GetDocument({id});
        setdocument(getData.document);
        setActive('home');
        setLoading(false);
    }

    const handleReversar = async id => {
        if(window.confirm('Confirma que desea reversar canje?')){
            setLoading(true);
            const reversar:any = await Api.ReversarCanje({id});
            setLoading(false);
            if(!reversar.success){
                Notification['error']({
                    title: reversar.message,
                    placement: 'topStart',
                });            
                return;
            }
            Notification['success']({
                title: "Canje reversado con éxito",
                placement: 'bottomEnd'
            });
            handleClose();
            runList();
        }
    }

    return (
        <ModalComponent show={show} handleClose={handleClose} title="Ver Documento">
            {loading ? <SimpleLoading /> : <>
            <Nav activeKey={active} onSelect={onSelect} appearance="tabs" style={{marginBottom: '20px'}}>
                <Nav.Item eventKey="home" icon={<Icon icon="home" />}>
                    Detalles
                </Nav.Item>
                <Nav.Item eventKey="historial">Historial</Nav.Item>
                <Nav.Item eventKey="canjes">Canjes</Nav.Item>
                <Nav.Item eventKey="intervenciones">Intervenciones</Nav.Item>
            </Nav>

            {active == 'home' && <>
            <h5>Detalles del documento</h5>
            <Form>
                <Grid fluid>
                    <Row>
                        <Col md={6}>
                            {document.origen_id ? <>
                                <ControlLabel style={{ fontSize: '12px', display: 'block' }}>Origen ID </ControlLabel>
                                <a href='#' onClick={() => handleVer(document.origen_id)}>
                                    { document.origen_id }
                                </a>
                            </> : <>
                            <ControlLabel style={{ fontSize: '12px' }}>ID Documento </ControlLabel>
                            <Input size="xs" readOnly={true} value={document.id_documento} />
                            </>}
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>COD Forma Pago</ControlLabel>
                            <Input size="xs" readOnly={true} value={document.cod_formapago} />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>NRO Cta Corriente</ControlLabel>
                            <Input size="xs" readOnly={true} value={document.nro_ctacte} />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Teléfono</ControlLabel>
                            <Input size="xs" readOnly={true} value={document.cod_orsan !== null ? document.telefono : 'NO REGISTRADO'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Rut tercero</ControlLabel>
                            <Input size="xs" readOnly={true} value={document.rut_tercero !== '' ? document.rut_tercero + '-' + document.dv_tercero : ''} />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>NRO Documento</ControlLabel>
                            <Input size="xs" readOnly={true} value={document.num_documento} />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Fecha Documento</ControlLabel>
                            <Input size="xs" readOnly={true} value={fecha(document.fecha_doc)} />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Monto</ControlLabel>
                            <Input size="xs" readOnly={true} value={document.monto} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Estado</ControlLabel>
                            <Input size="xs" readOnly={true} value={document.estado} />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Nombre</ControlLabel>
                            <Input size="xs" readOnly={true} value={document.nombre} />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Fecha Prog Ins</ControlLabel>
                            <Input size="xs" readOnly={true} value={fecha(document.fecha_prog_ins)} />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Fecha Prog Mod</ControlLabel>
                            <Input size="xs" readOnly={true} value={fecha(document.fecha_prog_mod)} />
                        </Col>
                    </Row>
                    {document.imagen && <>
                        <hr />
                        <a href={document.picture} target="_blank"> Imagen del Documento</a>
                    </>}
                    

                    <hr />
                    <Row>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Código Consulta</ControlLabel>
                            <Input size="xs" readOnly={true} value={document.cod_orsan !== null ? document.cod_orsan : 'NO REGISTRADO'} />
                        </Col>
                        {/* {document.bloqueado !== null && <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Bloqueado </ControlLabel>
                            <Input size="xs" readOnly={true} value={document.bloqueado == 1 ? 'SI': 'NO'} />
                        </Col>}
                        {document.fecha_bloqueo !== null && <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Fecha de Bloqueo</ControlLabel>
                            <Input size="xs" readOnly={true} value={fecha(document.fecha_bloqueo)} />
                        </Col>}
                        {document.bloqueado == '2' && <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Desbloqueado</ControlLabel>
                            <Input size="xs" readOnly={true} value={document.bloqueado == '2'? 'SI' : 'NO'} />
                        </Col>}
                        {document.fecha_desbloqueo !== null && <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Fecha de Desbloqueo</ControlLabel>
                            <Input size="xs" readOnly={true} value={fecha(document.fecha_desbloqueo)} />
                        </Col>}
                        {document.fecha_envio !== null && <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Fecha de Envío</ControlLabel>
                            <Input size="xs" readOnly={true} value={fecha(document.fecha_envio)} />
                        </Col>} */}
                        {document.fecha_protesto !== null && <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Fecha de Protesto</ControlLabel>
                            <Input size="xs" readOnly={true} value={fecha(document.fecha_protesto)} />
                        </Col>}
                        {/* {document.fecha_recepcion !== null && <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Fecha de Recepción</ControlLabel>
                            <Input size="xs" readOnly={true} value={fecha(document.fecha_recepcion)} />
                        </Col>} */}
                        {document.tipo_protesto !== null && <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Tipo de Protesto</ControlLabel>
                            <Input size="xs" readOnly={true} value={fecha(document.tipo_protesto)} />
                        </Col>}
                        {/* {document.respuesta_orsan !== null && <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Respuesta Orsan</ControlLabel>
                            <Input size="xs" readOnly={true} value={fecha(document.respuesta_orsan)} />
                        </Col>}                         */}
                    </Row>
                </Grid>
            </Form>
            </>}

            {active == 'historial' && <>
            <h5>Historial del documento</h5>
            <SimpleTable
                autoscroll={'auto'}
                height="237px"
                messageEmpty={'No hay Historial'}
                data={document.historial}
                onLoadingTable={false}
                trHeader={[
                    {thClassName:'samll px-2 pb-0 text-white text-center',content:'Fecha'},
                    {thClassName:'samll px-2 pb-0 text-white text-left',content:'Log'},
                ]}
                trBody={[
                    {
                        tdClassName:'small px-2 py-0 pt-1 text-center',
                        attrData:'created_at',
                        content: item => moment(item).local().format('DD/MM/YYYY H:m:s')
                    },
                    {tdClassName:'small px-2 py-0 pt-1 text-left', attrData:'log'},
                ]}
            /></>}

            {active == 'canjes' && <>
                {document.estado === 'RCAN' && <div style={{textAlign: 'right'}}>
                    <Button appearance="subtle" active onClick={() => handleReversar(document.id)} style={{color: 'white' , backgroundColor: '#35485f'}}>
                        Reversar Canje
                    </Button>
                </div>}
                <SimpleTable
                    autoscroll={'auto'}
                    height="237px"
                    messageEmpty={'No hay Canjes Registrados'}
                    data={document.canjes}
                    onLoadingTable={false}
                    trHeader={[
                        {thClassName:'samll px-2 pb-0 text-white text-center',content:'Tipo'},
                        {thClassName:'samll px-2 pb-0 text-white text-right',content:'Monto'},
                        {thClassName:'samll px-2 pb-0 text-white text-center',content:'Fecha'},
                        {thClassName:'samll px-2 pb-0 text-white text-center',content:'Documento'},
                    ]}
                    trBody={[
                        {tdClassName:'small px-2 py-0 pt-1 text-left', attrData:'tipo_canje'},
                        {tdClassName:'small px-2 py-0 pt-1 text-right', attrData:'monto', content:item=>Amount(item)},
                        {
                            tdClassName:'small px-2 py-0 pt-1 text-center',
                            attrData:'fecha',
                            content: item => moment(item).local().format('DD/MM/YYYY')
                        },
                        {tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'id_cheque', content: item => <a href='#' onClick={() => handleVer(item)}> {item} </a>},
                    ]}
                />
            </>}

            {active == 'intervenciones' && <>
                <SimpleTable
                    autoscroll={'auto'}
                    height="237px"
                    messageEmpty={'No hay Intervenciones para este documento'}
                    data={document.origen_id ?  document.liberaciones : document.intervenciones}
                    onLoadingTable={false}
                    trHeader={[
                        {thClassName:'samll px-2 pb-0 text-white text-center',content:'Rol'},
                        {thClassName:'samll px-2 pb-0 text-white text-center',content:'Pam Nro'},
                        {thClassName:'samll px-2 pb-0 text-white text-center',content:'Cod Prestación'},
                        {thClassName:'samll px-2 pb-0 text-white text-center',content:'Rol Prof.'},
                        {thClassName:'samll px-2 pb-0 text-white text-center',content:'Rut Prof.'},
                        {thClassName:'samll px-2 pb-0 text-white text-center',content:'Fecha Transacción'},
                        {thClassName:'samll px-2 pb-0 text-white text-center',content:'Monto'},
                    ]}
                    trBody={[
                        {tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'Admision_Rol_Clinica'},
                        {tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'pam_numero'},
                        {tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'cod_prestacion'},
                        {tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'rol_prof'},
                        {tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'rut_prof'},
                        {
                            tdClassName:'small px-2 py-0 pt-1 text-center',
                            attrData:'fecha_transaccion',
                            content: item => moment(item).local().format('DD/MM/YYYY')
                        },
                        {tdClassName:'small px-2 py-0 pt-1 text-right', attrData:'monto_prestacion', content:item=>Amount(item)},
                    ]}
                />
            </>}

            </>}

            

        </ModalComponent>
    )
}

export default ModalVerDocumento