import React, { useState, useEffect } from 'react'
import { Col, ControlLabel, Form, Grid, Input, Row, DatePicker, Notification } from 'rsuite'
import SimpleLoading from '../../components/loading/SimpleLoading';
import ModalComponent from './ModalComponent'
import * as Api from '../../api/end.points';
import InfoDocumento from './InfoDocumento';
import { Calendar } from '../../services/Utils';

const ModalAgregarOrsan = (props) => {
    const {document, show, handleClose, fecha, runList} = props;
    const [inputs, setInputs]:any = useState({});
    const [loading, setLoading]:any = useState(false);

    const handleInput = (name:any, value:any) =>{
        setInputs({
            ...inputs,
            [name]: value,
            id: document.id
        });
	}

    useEffect(()=>{
        var todayDate = new Date().toISOString().slice(0, 10);
        setInputs({
            ...inputs,
            fecha: document.fecha_cod_orsan ? document.fecha_cod_orsan : todayDate,
            id: document.id,
            cod_orsan: document.cod_orsan
        });
	},[document]);

    const GuardarOrsan = async () => {
        
        if(!('fecha' in inputs) || inputs.fecha === '' || !inputs.fecha){
            Notification['error']({
                title: 'Debe seleccionar una Fecha.',
                placement: 'topStart',
            });
            return false;
        }

        if(!('cod_orsan' in inputs) || inputs.cod_orsan === '' || !inputs.cod_orsan){
            Notification['error']({
                title: 'Debe ingresar el código consulta.',
                placement: 'topStart',
            });
            return false;
        }
        setLoading(true);
        
        const saveData:any = await Api.SaveOrsan(inputs);
        Notification['success']({
            title: "Guardado con éxito",
            placement: 'bottomEnd'
        });

        setLoading(false);

        runList();
        handleClose();
    }

    return (
        <ModalComponent show={show} handleClose={handleClose} title="Agregar Código Consulta" guardar={GuardarOrsan}>
            {loading ? <SimpleLoading /> :
            <Form>
                <Grid fluid>
                    <InfoDocumento document={document} fecha={fecha} />
                    <Row>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Fecha </ControlLabel>
                            <DatePicker block size="xs" value={inputs.fecha} 
                                locale={Calendar}
                                onChange={ (e:any) => {
                                    if (e) {
                                        let year = e.getFullYear();
                                        let month = e.getMonth()+1;
                                        let day = e.getDate();
                                        day =  day >= 10 ? day : `0${day}`;
                                        month =  month >= 10 ? month : `0${month}`;
                                        let fecha = `${year}-${month}-${day}`;
                                        handleInput('fecha', fecha)
                                    } else {
                                        handleInput('fecha', '')
                                    }
                                }}
                            />
                        </Col>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Código Consulta</ControlLabel>
                            <Input size="xs" value={inputs.cod_orsan}
                                onChange={ (e:any, ev:any) => {
                                    handleInput('cod_orsan', ev.target.value)
                                }}  
                            />
                        </Col>
                    </Row>
                </Grid>
            </Form>}
        </ModalComponent>
    )
}

export default ModalAgregarOrsan