import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'rsuite';

const ModalComponent = (props:any) => {
    const {show, handleClose, title, titleCerrar='Cerrar', titleGuardar='Guardar', guardar} = props;
    return(
        <Modal show={show} onHide={handleClose} animation={false} centered keyboard={true} backdrop={true} size="lg" id="ModalComponent">
            <Modal.Header style={{background: '#253242'}} closeButton>
                <Modal.Title style={{fontSize: '16px', color: '#fff'}}>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} appearance="default" active>
                    {titleCerrar}
                </Button>

                {guardar && <Button appearance="subtle" active onClick={guardar} style={{color: 'white' , backgroundColor: '#35485f'}}>
                    {titleGuardar}
                </Button>}
            </Modal.Footer>
        </Modal>
    )
}

export default ModalComponent;