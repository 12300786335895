import React, { useState, useEffect } from 'react'
import { Col, ControlLabel, Form, Grid, Input, Row, DatePicker, Notification, Uploader, Button } from 'rsuite'
import SimpleLoading from '../../components/loading/SimpleLoading';
import ModalComponent from './ModalComponent'
import * as Api from '../../api/end.points';
import InfoDocumento from './InfoDocumento';

const ModalAdjuntarImagen = (props) => {
    const {document: doc, show, handleClose, fecha} = props;
    const [inputs, setInputs]:any = useState({});
    const linkApi=process.env.REACT_APP_ENV_API_URL + "/adjuntarimagen";
    const [fileList, setfileList]:any = useState([]);
    const [imagen, setimagen]:any = useState(null);

    useEffect(()=>{
        setInputs({
            ...inputs,
            id: doc.id
        });
        if(doc.imagen){
            setfileList([{
                name: doc.picture,
                fileKey: doc.id,
                url: doc.picture
            }]);
            setimagen(doc.imagen);
        } else {
            setfileList([]);
            setimagen(null);
        }

	},[doc]);

    return (
        <ModalComponent show={show} handleClose={handleClose} title="Adjuntar Imagen">
            <Form>
                <Grid fluid>
                    <InfoDocumento document={doc} fecha={fecha}  />
                    <Row>
                        <Col md={24}>
                            <Uploader 
                                disabled={imagen ? true : false}
                                listType="picture-text" 
                                action={ linkApi } 
                                data={{ id: doc.id }} 
                                defaultFileList={fileList} 
                                onSuccess={(response:any) => {
                                    setfileList([{
                                        name: response.fileName,
                                        fileKey: doc.id,
                                        url: response.fileName
                                    }]);
                                    setimagen(response.fileName);
                                }}
                                onRemove ={async (file:any) => {
                                    const removerimagen:any = await Api.RemoveImage({id: file.fileKey});
                                    setfileList([]);
                                    setimagen(null);
                                }}
                                >
                                <Button> Adjuntar Imagen</Button>
                            </Uploader>
                        </Col>
                    </Row>
                </Grid>
            </Form>
        </ModalComponent>
    )
}

export default ModalAdjuntarImagen