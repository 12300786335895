import React from 'react';
import PropTypes from 'prop-types';
import SimpleLoading from '../loading/SimpleLoading';
import { findObject, HashString } from '../../services/Utils';

const SimpleTable = ( props:any ) => {
	const {
		trHeader, trBody, data, title, onLoadingTable, messageEmpty, height, autoscroll
	} = props;
	const dataLength = data != null ? data.length :  0;
	const trHeaderLength = trHeader.length;
	const Title = () => {
		if (title!=null) {
			return (
				<>
					<h5 className="mt-3">
						{title}
					</h5>
					<hr />
				</>
			)
		}else{
			return null
		}
	}
	const hash = HashString();
	const message = onLoadingTable === true ? <SimpleLoading/> : messageEmpty;
	return(
		<div className="col-12 px-0 simpletable" style={{height: height, overflow: autoscroll}}>
			<Title/>
			<table className="table table-hover shadow simple-table table-bordered">
				<thead className="simple-table-thead">
					<tr>
						{
							trHeaderLength > 0 ? trHeader.map(((th:any, key:any) => {
								const { content, thClassName } = th;
								return (
									<th key={`tr-head-${key}-${hash}`} className={`${thClassName}`}>
										{content}
									</th>
								);
							})) : <th className="text-center" colSpan={dataLength}>Sin encabezados</th>
						}
					</tr>
				</thead>
				<tbody className="bg-white">
					{
						dataLength > 0 ? data.map(((row:any, i:any) => {
							const key = i;
							const TrExtra= row.trExtra ? row.trExtra : null;
							return (
								<React.Fragment key={`tr-body-${key}-${hash}`}>
								<tr>
									{
										trBody.length > 0 ?  trBody.map(((template:any, key2:any) => {
											const { content, tdClassName, attrData } = template;
											const value = findObject(row, attrData);
											const dataContent = (typeof content !== 'undefined' ? content(value,row) : value) ;
											return(
												<td key={`td-${key}-${key2}-${hash}`} className={`${tdClassName}`}>
													{dataContent}
												</td>
											);
										}), row) : <td colSpan={trHeaderLength}>{message}</td>
									}
								</tr>
								{
									TrExtra != null && dataLength >0  ?
									<tr>
										<td colSpan={trHeaderLength} className="py-0">
											{TrExtra(row)}
										</td>
									</tr> : null
								}
								</React.Fragment>
							);
						}),trBody) : <tr><td className="text-center" colSpan={trHeaderLength}>{message}</td></tr>
					}
				</tbody>
			</table>
		</div>
	)
}

SimpleTable.propTypes = {
	data: PropTypes.array,
	trHeader: PropTypes.arrayOf(PropTypes.shape({
		content: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.string,
		]).isRequired,
		thClassName: PropTypes.string
	})),
	trBody: PropTypes.arrayOf(PropTypes.shape({
		content: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.string,
			PropTypes.any,
		]),
		tdClassName: PropTypes.string.isRequired,
		attrData: PropTypes.string.isRequired
	})),
	title: PropTypes.string,
	onLoadingTable: PropTypes.bool,
	messageEmpty: PropTypes.string,
};

SimpleTable.defaultProps = {
	data: [],
	trHeader: [],
	trBody: {},
	title:null,
	onLoadingTable:false,
	messageEmpty:'Sin Información',
	height: null,
	autoscroll: 'unset'
};

export default SimpleTable;