import React, { useState, useEffect } from 'react'
import { Col, ControlLabel, Form, Grid, Input, Row, DatePicker, Notification, SelectPicker } from 'rsuite'
import SimpleLoading from '../../components/loading/SimpleLoading';
import ModalComponent from './ModalComponent'
import * as Api from '../../api/end.points';
import InfoDocumento from './InfoDocumento';

const ModalTipoProtesto = (props) => {
    const {document, show, handleClose, fecha, runList} = props;
    const [inputs, setInputs]:any = useState({});
    const [loading, setLoading]:any = useState(false);

    const handleTipoProtesto = (value) => setInputs({...inputs, tipo: value, id: document.id});

    useEffect(()=>{
        setInputs({
            ...inputs,
            tipo: document.tipo_protesto,
            id: document.id
        });
	},[document]);

    const options = [{
        "label": "Forma",
        "value": "Forma"
      },
      {
        "label": "ONP",
        "value": "ONP"
      },
      {
        "label": "Falta de fondos",
        "value": "Falta de fondos"
      },
      {
        "label": "Firma disconforme",
        "value": "Firma disconforme"
      }
    ];

    const Guardar = async () => {
        
        if(!('tipo' in inputs) || inputs.tipo === '' || !inputs.tipo){
            Notification['error']({
                title: 'Debe seleccionar un Tipo de protesto.',
                placement: 'topStart',
            });
            return false;
        }

        setLoading(true);
        
        const saveData:any = await Api.TipoProtesto(inputs);
        Notification['success']({
            title: "Tipo de protesto guardado con éxito",
            placement: 'bottomEnd'
        });

        setLoading(false);

        runList();
        handleClose();
    }

    return (
        <ModalComponent show={show} handleClose={handleClose} title="Tipo de protesto" guardar={Guardar}>
            {loading ? <SimpleLoading /> :
            <Form>
                <Grid fluid>
                    <InfoDocumento document={document} fecha={fecha} />
                    <Row>
                        <Col md={6}>
                            <ControlLabel style={{ fontSize: '12px' }}>Tipo de protesto</ControlLabel>
                            <SelectPicker
                                value={inputs.tipo}
                                onChange={handleTipoProtesto}
                                data={options}
                                style={{ width: 224 }}
                                // size="xs"
                            />
                        </Col>
                    </Row>
                </Grid>
            </Form>}
        </ModalComponent>
    )
}

export default ModalTipoProtesto